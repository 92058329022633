export var getAverageIndices = function getAverageIndices(indexTracker, nrOfObjs) {
  var avgIndices = [];

  for (var _i = 0, _Object$keys = Object.keys(indexTracker); _i < _Object$keys.length; _i++) {
    var key = _Object$keys[_i];

    if (indexTracker[key].nr < 2) {
      indexTracker[key].indexSum += 100;
    }

    avgIndices.push({
      objectID: key,
      avgOfIndices: indexTracker[key].indexSum / nrOfObjs
    });
  }

  return avgIndices.sort(function (a, b) {
    return a.avgOfIndices > b.avgOfIndices ? 1 : -1;
  });
};