import { mapToRecommendations } from './utils';
import { version } from './version';
export function getRelatedProducts(_ref) {
  var objectIDs = _ref.objectIDs,
      recommendClient = _ref.recommendClient,
      _ref$transformItems = _ref.transformItems,
      transformItems = _ref$transformItems === void 0 ? function (x) {
    return x;
  } : _ref$transformItems,
      fallbackParameters = _ref.fallbackParameters,
      indexName = _ref.indexName,
      maxRecommendations = _ref.maxRecommendations,
      queryParameters = _ref.queryParameters,
      threshold = _ref.threshold;
  var queries = objectIDs.map(function (objectID) {
    return {
      fallbackParameters: fallbackParameters,
      indexName: indexName,
      maxRecommendations: maxRecommendations,
      objectID: objectID,
      queryParameters: queryParameters,
      threshold: threshold
    };
  });
  recommendClient.addAlgoliaAgent('recommend-core', version);
  return recommendClient.getRelatedProducts(queries).then(function (response) {
    return mapToRecommendations({
      maxRecommendations: maxRecommendations,
      hits: response.results.map(function (result) {
        return result.hits;
      }),
      nrOfObjs: objectIDs.length
    });
  }).then(function (hits) {
    return {
      recommendations: transformItems(hits)
    };
  });
}