import { getAverageIndices } from './computeAverageIndices';
/**
 * Returns the reordered list of recommendations based on average indices.
 *
 * @param hits - recommendations
 * @param maxRecommendations - max number of recommendations
 * @param nrOfObjs - total number of products for which we are retrieving recommendations (objectsIDs.length)
 */

export function mapToRecommendations(_ref) {
  var hits = _ref.hits,
      maxRecommendations = _ref.maxRecommendations,
      nrOfObjs = _ref.nrOfObjs;
  var indexTracker = {};
  hits.forEach(function (hitsArray) {
    hitsArray.forEach(function (hit, index) {
      if (!indexTracker[hit.objectID]) {
        indexTracker[hit.objectID] = {
          indexSum: index,
          nr: 1
        };
      } else {
        indexTracker[hit.objectID] = {
          indexSum: indexTracker[hit.objectID].indexSum + index,
          nr: indexTracker[hit.objectID].nr + 1
        };
      }
    });
  });
  var sortedAverageIndices = getAverageIndices(indexTracker, nrOfObjs);
  var finalOrder = sortedAverageIndices.reduce(function (orderedHits, avgIndexRef) {
    var result = hits.flat().find(function (hit) {
      return hit.objectID === avgIndexRef.objectID;
    });
    return result ? orderedHits.concat(result) : orderedHits;
  }, []);
  return finalOrder.slice(0, // We cap the number of recommendations because the previously
  // computed `hitsPerPage` was an approximation due to `Math.ceil`.
  maxRecommendations && maxRecommendations > 0 ? maxRecommendations : undefined);
}